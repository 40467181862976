const supportedLanguages = [
  {
    key: "en",
    value: "ENG",
    img: ".././flags/EN.webp"
  },
  {
    key: "tr",
    value: "TUR",
    img: ".././flags/turkey.png"
  },
  {
    key: "es",
    value: "ESP",
    img: ".././flags/SP.webp"
  },
  {
    key: "pt",
    value: "PRT",
    img: ".././flags/portugal.png"
  },
  {
    key: "it",
    value: "ITA",
    img: ".././flags/ita.png"
  },
  {
    key: "de",
    value: "GER",
    img: ".././flags/ger.webp"
  },
  {
    key: "fr",
    value: "FRE",
    img: ".././flags/FR.webp"
  },
  {
    key: "el",
    value: "GRE",
    img: ".././flags/Gre.webp"
  },
  {
    key: "sr",
    value: "SRP",
    img: ".././flags/Srp.webp"
  },
  {
    key: "nl",
    value: "NL",
    img: ".././flags/DE.png"
  },
];

export default supportedLanguages;
